/* Контейнер для снежинок, фиксированный на экране */

.snow-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.snowflake {
  position: absolute;
  opacity: 0.9;
  animation: fall linear infinite;
  pointer-events: none;
}

@keyframes fall {
  0% {
    transform: translateY(-100px) rotate(0deg);
  }

  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}
