.sticky-new-year-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100px;
  margin: 0;
  padding: 0;
  background-image: url('../../../assets/newyearfooter/newyearfooter.png'); /* Указываем путь к изображению */
  background-repeat: repeat-x;
  background-position: bottom left;
  background-size: auto 100%;
}
