p{
  margin: 0;
  padding: 0;
}

.second-header__general {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
  padding-bottom: 10px;

  /* padding: 20px 50px 10px; */
  padding-left: 50px;
}

.second-header__nav_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.second-header__icons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  list-style-type: none;
}

.second-header__icon {
  width: 30px;
  height: 26px;
  cursor: pointer;
}

.second-header__icon_y {
  scale: 1.4;
}

.second-header__logo {
  max-width: 400px;
}

.second-header__icon:hover {
  transform: scale(1.5);
  transition: all 0.2s ease-in-out 0s;
}

.second-header__quotation {
  max-width: 250px;
}

.second-header__xs_tree {
  max-height: 300px;
}

.second-header__xs_logos{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

@media (width <= 1350px) {
  .second-header__quotation {
    text-align: right;
  }
}

@media (width <= 1150px) {
  .second-header__general {
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .second-header__xs_tree {
    max-height: 200px;
  }

  .second-header__quotation {
    align-items: center;
    margin-top: 0;

    /* display: flex; */
    text-align: center;
  }

  /* .second-header__logo {
    order: -1;
  } */

  .second-header__quotation {
    margin-top: 40px;
  }

  .second-header__icons {
    margin-top: 20px;
  }
}

@media (width <= 600px) {
  .second-header__xs_logos {
    flex-direction: column;
  }
}


@media (width <= 450px) {
  .second-header__logo {
    max-width: 350px;
  }
}
